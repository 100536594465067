@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-sectionBox';

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  border: 1px solid color.$st-neutral-default;
  padding: 20px;
  flex-direction: column;
  border-radius: 15px;
  background-color: color.$bg-neutral-default;
  overflow-x: hidden;
  height: min-content;

  &__titleArea {
    margin-bottom: 8px;
  }
  &__title {
    @include text('title-2');
  }
  &__outerTitle {
    font-size: 22px;
    font-weight: 600;
    margin: 0;
  }
  ul {
    margin-block-start: 0px;
    li {
      margin-bottom: 10px;
    }
  }
}
