@use '@/uikit/styles/colors.scss' as color;
@use 'sass:map';

$baseClass: 'c-newIcon';

.#{$baseClass} {
  &__primary {
    color: color.$icon-primary;

    :hover>& {
      color: color.$icon-primary-hover;
    }
  }

  &__secondary {
    color: color.$icon-secondary;

    :hover>& {
      color: color.$icon-secondary-hover;
    }
  }

  &__danger {
    color: color.$icon-danger;

    :hover>& {
      color: color.$icon-danger-hover;
    }
  }

  &__tertiary {
    color: color.$icon-tertiary;

    :hover>& {
      color: color.$icon-tertiary-hover;
    }
  }

  &__purple {
    color: color.$icon-purple;

    :hover>& {
      color: color.$icon-purple-hover;
    }
  }

  &__tableaction {
    opacity: 0.65;
    color: transparent;
    path {
      stroke: color.$st-neutral-3-active;
    }
  }

  &__tablefooter {
    color: color.$icon-tertiary;

    :hover>& {
      color: color.$icon-tertiary-hover;
    }
  }

  &__sidebartab {
    color: color.$nav-icon-fill;

    path {
      stroke: color.$nav-icon-stroke;
    }

  }

  &__menu {
    color: color.$nav-icon-fill;

    path {
      stroke: color.$nav-icon-stroke;
    }
  }
}