@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-row';

.#{$baseClass} {
  display: flex;
  flex-direction: row;
}
