.font-size-14 {
    font-size: 14px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-26 {
    font-size: 26px;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.color-red {
    color: #e9455e;
}

.width-full {
    width: 100%;
}

.margin-auto {
    margin: auto;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-bottom-18 {
    margin-bottom: 18px;
}

.sidebar-loader {
    margin: 200px auto auto auto;
    font-size: 40px;
}

.min-width-fit-content {
    min-width: fit-content;
}