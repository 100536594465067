@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-ffInput';

.#{$baseClass} {
  height: 35px;
  font-family: 'Noto Sans', Arial, Helvetica, sans-serif;
  @include text('body-2');

  input {
    font-family: inherit;
  }
}

.ant-input .#{$baseClass},
.#{$baseClass} {  
  &.has-error {
    border-color: color.$bg-error-default !important;
  }
}