@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$_text: (
  title-1: (
    font-size: font.$size-2xl,
    font-weight: font.$weight-semibold,
    line-height: 36px,
    letter-spacing: -0.8px,
    color: color.$fg-neutral-default
  ),
  title-2: (
    font-size: font.$size-xl,
    font-weight: font.$weight-semibold,
    font-family: font.$family-primary,
    line-height: 32px,
    letter-spacing: -0.48px,
    color: color.$fg-neutral-default
  ),
  title-3: (
    font-size: font.$size-lg,
    font-weight: font.$weight-semibold,
    line-height: 28px,
    letter-spacing: -0.36px,
    color: color.$fg-neutral-default
  ),
  title-4: (
    font-size: font.$size-base,
    font-weight: font.$weight-semibold,
    font-family: font.$family-primary,
    line-height: 24px,
    letter-spacing: -0.48px,
    color: color.$fg-neutral-default
  ),  
  body-1: (
    font-size: 16px,
    font-weight: font.$weight-semibold,
    line-height: 24px,
    letter-spacing: -0.16px,
    color: color.$fg-neutral-default
  ),
  body-2-strong: (
    font-size: 14px,
    font-weight: font.$weight-bold,
    line-height: 21px,
    letter-spacing: -0.14px,
    color: color.$fg-neutral-default
  ),
  body-2: (
    font-size: 14px,
    font-weight: font.$weight-normal,
    line-height: 20.5px,
    letter-spacing: -0.14px,
    color: color.$fg-neutral-default
  ),
  caption-1-strong: (
    font-size: 12px,
    font-weight: font.$weight-semibold,
    line-height: 16px,
    letter-spacing: -0.12px,
    color: color.$fg-neutral-default
  ),
  caption-1: (
    font-size: 12px,
    font-weight: font.$weight-normal,
    line-height: 16px,
    letter-spacing: -0.12px,
    color: color.$fg-neutral-default
  ),
  caption-2: (
    font-size: 10px,
    font-weight: font.$weight-normal,
    line-height: 12px,
    letter-spacing: -0.1px,
    color: color.$fg-neutral-default
  ),
);

@mixin text($text-style, $mod: false) {
  @if not(map.has-key($_text, $text-style)) {
    @error '#{$text-style}: undefined text style';
  }
  $text-style-map: map.get($_text, $text-style);
  @each $property, $value in $text-style-map {
    #{$property}: $value;
  }

  // @if ($mod) {
  //   @include mod_rtl {
  //     font-family: $_font-family-rtl;
  //   }
  //   @include mod_ltr {
  //     font-family: $_font-family-ltr;
  //   }
  // } @else {
  //   @include rtl {
  //     font-family: $_font-family-rtl;
  //   }
  //   @include ltr {
  //     font-family: $_font-family-ltr;
  //   }
  // }
}
