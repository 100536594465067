@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-conditionRoute';

.#{$baseClass} {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-bottom: 1px solid #e1e6eb;
  background-color: #fff;

  i {
    cursor: pointer;
  }

  &--moreThanOneGroup {
    .#{$baseClass} {
      &__routeOperator {
        &::before {
          height: 3px;
          width: 10px;
          background-color: #0089ff;
          position: absolute;
          left: -10px;
          top: 10px;
          content: ' ';
        }
      }
      &__groupsWrapper {
        &::before {
          height: calc(100% - 70px);
          width: 3px;
          background-color: #0089ff;
          position: absolute;
          left: 5px;
          top: 25px;
          content: ' ';
        }
      }
      &__group {
        &::before {
          height: 3px;
          width: 10px;
          background-color: #0089ff;
          position: absolute;
          left: -10px;
          top: calc(100% - 25px);;
          content: ' ';
        }
      }
    }
  }
  &__routeOperator {
    position: relative;
  }
  &__shiftIcon {
    font-size: 22px;
    opacity: 0.5;

    &:hover {
      opacity: 0.8;
    }
  }
  &__routeIndex {
    min-width: 22px;
    min-height: 22px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #0958d9;
    background-color: #e6f4ff;
    border: 1px solid #b9ddff;
    border-radius: 50%;
  }
  &__routeName {
    font-weight: 600;
  }
  &__routeConfigWrapper {
    display: flex;
    flex-direction: column;
    padding: 12px 12px 12px 15px;
    border: 1px solid #c2d2d9;
    border-radius: 4px;
    background: #f4f8fc;
    position: relative;
  }
  &__groupsWrapper {
    padding: 12px 12px 12px 15px;
    border: 1px solid #c2d2d9;
    border-radius: 4px;
    background: #f4f8fc;
    position: relative;
  }
  &__group {
    border: 1px solid #c2d2d9;
    background-color: #fff;
    padding: 10px 15px 0px 15px;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 10px;
    position: relative;

    &--moreThanOneRule {
      .#{$baseClass} {
        &__groupOperator {
          position: relative;

          &::before {
            height: 3px;
            width: 10px;
            background-color: #fda74e;
            position: absolute;
            left: -10px;
            top: 11px;
            content: ' ';
          }
        }
        &__groupInnerContent {
          &::before {
            height: calc(100% - 53px);
            width: 3px;
            background-color: #fda74e;
            position: absolute;
            left: 5px;
            top: 23px;
            content: ' ';
          }
        }
        &__selectField {
          position: relative;

          &::before {
            height: 3px;
            width: 10px;
            background-color: #fda74e;
            position: absolute;
            left: -10px;
            top: 15px;
            content: ' ';
          }
        }
      }
    }
  }
}
