@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffTextarea';

.ant-input {
  &.#{$baseClass} {
    min-height: 35px !important;
    padding-top: 7px;

    &--notResizable {
      resize: none;
      overflow-y: auto;
    }
  }
}
