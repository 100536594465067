@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$_nav_styles: (
  header-text: (
    font-weight: 600,
    font-size: 16px,
    color: #2a3447,
    white-space: nowrap,
    letter-spacing: 0.3px
  ),  
  sidebar: (
    font-family: font.$family-secondary,
    background-color: color.$bg-neutral-default,
    border-right: 1px solid color.$st-neutral-default,
  ),
  create-new: (
    font-family: font.$family-primary,
    font-weight: font.$weight-normal,
    height: 36px,
    width: 100%,
    font-size: 16px,
    background-color: color.$dark-blue,
    border-radius: 10px,
    border-width: 0px,
    color: #fff,
    fill: #fff,
    align-items: center,
    justify-content: center,
    white-space: nowrap,
  ),
  create-new-hover: (
    background-color: color.$bg-info-active,
    cursor: pointer,
  ),
  item-hover: (
    background-color: #ecf2f7,
    cursor: pointer,
  ),
  item-disabled: (
    opacity: 0.3,
    cursor: not-allowed,
    pointer-events: none,
  ),
  item-active: (
    background-color: #ddedfd,
  ),
  menu-level-1: (
    font-weight: 500,
    font-size: 16px,
    color: #2d3257,
    margin-left: 30px,
  ),
  menu-level-2: (
    font-weight: 400,
    font-size: 15px,
    height: 36px,
    padding: 2px 0px 2px 40px,
    border-radius: 10px,
    color: #2d3257,
    align-items: center,
    text-decoration: none,
  ),
  menu-level-2-with-child: (
    font-weight: 400,
    font-size: 15px,
  ),
  menu-level-3: (
    padding: 8px 0px 8px 10px,
    margin-left: 10px,
    color: #8a90ad,
    height: 24px,
  ),
  menu-level-3-children: (
    border-left: 1px solid #d4d4d4,
    margin-left: 20px,
    padding: 6px 0px 6px 0px,
    list-style-type: none,
    gap: 6px,
  ),
  collapsed-popup: (
    background-color: #fff,
    font-size: 15px,
    box-shadow: 0 0 5px 0 #0000000d,
    border-radius: 8px,
    position: fixed,
    min-width: 200px,
    border: 1px solid #e1e6eb,
    padding: 10px,
    z-index: 1000,
    max-height: 300px,
    overflow-y: auto,
  )
);

@mixin nav($nav-style, $mod: false) {
  @if not(map.has-key($_nav_styles, $nav-style)) {
    @error '#{$nav-style}: undefined text style';
  }
  $nav-style-map: map.get($_nav_styles, $nav-style);
  @each $property, $value in $nav-style-map {
    #{$property}: $value;
  }

  // @if ($mod) {
  //   @include mod_rtl {
  //     font-family: $_font-family-rtl;
  //   }
  //   @include mod_ltr {
  //     font-family: $_font-family-ltr;
  //   }
  // } @else {
  //   @include rtl {
  //     font-family: $_font-family-rtl;
  //   }
  //   @include ltr {
  //     font-family: $_font-family-ltr;
  //   }
  // }
}
