@use '@/uikit/styles/text' as *; 
@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-simpleFlowForm';

.#{$baseClass} {
  &__pathItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 12px 12px;
  }
  &__pathItemName {
    display: flex;
    align-items: center;
    gap: 4px;
    @include text('body-2-strong');
  }
  &__addNewPath {
    @include text('body-1');
  }
}
