@use '@/uikit/styles/text' as *;
@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-pathEditor';

.#{$baseClass} {
  &__pathName {
    @include text('body-1');
  }
}
