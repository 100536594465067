@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffSwitch';

.#{$baseClass} {
    display: flex;
    align-items: center;
    gap: 10px;
    
    &--disabledCompletely {
        ~ .#{$baseClass}__label {
            color: color.$fg-neutral-disabled;
        }
    }
    &--redOnOff {
        &.#{$baseClass}--disabled {
            background-color: red;
        }
    }
    &__button {
        width: auto;
    }
    &__label {
        color: color.$fg-neutral-default;
    }
}