@use '@/uikit/styles/colors.scss' as color;
@use '@/uikit/styles/text.scss' as *;
@use 'sass:map';

$baseClass: 'c-dataPassing';

.#{$baseClass} {
  &__headerText {
    @include text('body-2-strong');
  }
}
