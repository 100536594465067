@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-ffAddGroup';

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__addIcon {
    cursor: pointer;
    font-size: 24px;
    color: color.$primary-blue !important;
  }
  &__col {
    display: flex;
    flex-direction: column;
    flex: 1;
    @include text('body-2');

    &--rightText {
      text-align: right;
    }
    &--leftText {
      text-align: left;
    }
    &--centeredText {
      text-align: center;
    }
    &--bold {
      @include text('body-2-strong');
    }
  }
  &__row {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  &__addDivider {
    width: 100%;
    min-width: 100px !important;
    max-width: 300px;
    margin: 5px auto 0px !important;
    &::before,
    &::after {
      top: 0px !important;
    }
  }
}
