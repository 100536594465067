@use '@/uikit/styles/base_colors.scss';
@use '@/uikit/styles/colors.scss' as color;
@import url('https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Roboto:100,300,400,500,600,700,900|Noto+Sans:wght@400;500;600;700&display=swap');
@import url('./commonClassNames.scss');

@font-face {
  font-family: SFMono;
  font-weight: 500;
  src: url('./assets/fonts/SFMonoMedium.ttf') format('truetype');
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
}

#root {
  height: 100vh;
}

#root p,
.ant-modal-root p {
  margin: 0;
  margin-bottom: 0px;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre {
  margin: 0;
  padding: 0;
  border: 0;
  color: color.$fg-neutral-default;
}

ul, li, ol {
  color: color.$fg-neutral-default;
}

a {
  color: color.$fg-info-2-default;
}

.ant-tag {
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  border-radius: 5px;
  margin: 0;
  padding: 0 7px;
}

.gist-messenger-iframe {
  box-shadow: 0px 5px 40px rgba(0,0,0,0.3) !important;
	@media only screen and (min-width: 800px) {
    min-height: 400px;
  	width: 450px!important;
	}
  @media only screen and (min-width: 1200px) {
		width: 500px!important;
    min-height: 500px;
	}
	@media only screen and (min-width: 1600px) {
		width: 500px!important;
    min-height: 600px;
	}
	@media only screen and (min-width: 1800px) {
		width: 600px!important;
    min-height: 750px;
	}
  @media only screen and (min-width: 2300px) {
		width: 600px!important;
    min-height: 1000px;
	}
}

.gist-article-sidebar-iframe > iframe{
	box-shadow: 0px 5px 40px rgba(0,0,0,0.3) !important;
	@media only screen and (max-width: 1599px) {
  	width: 100%!important;
    max-width: 700px;
	}
	@media only screen and (min-width: 1600px) {
		width: 850px!important;
	}
	@media only screen and (min-width: 1921px) {
		width: 950px!important;
	}
}

#canny-changelog-iframe {
  left: 95px !important;
  bottom: 70px !important;
  right: unset !important;
  top: unset !important;
}