@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffModal';

.#{$baseClass} {
  .ant-modal-footer {
    padding: 12px 16px;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-mask {
    display: none;
  }
  &__closeIcon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    color: color.$fg-neutral-3-default;
    justify-content: center;
    align-items: center;

    &:hover {
      color: color.$fg-neutral-3-hover;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    color: color.$fg-neutral-default;
  }
}

.ant-modal-root {
  .ant-modal-body {
    padding: 8px 30px 20px 30px;
  }
  .ant-modal-content {
    border-radius: 8px;
    padding: 0;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 0.5px solid color.$st-neutral-default;
    padding: 16px 16px;
  }
}
