@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-stepCheck';

.#{$baseClass} {
  width: 28px;
  max-width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #108ee9;
  color: #fff;
  font-size: 14px;

  &--checked {
    background-color: transparent;
    border: 1.5px solid #108ee9;
    color: #108ee9;
  }
}
