@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-codeSnippet';

.#{$baseClass} {
  background-color: color.$bg-neutral-2-default;
  padding: 5px 5px 5px 10px;
  border-radius: 4px;
  overflow-y: auto;
  position: relative;
  overflow: hidden;
  min-height: 30px;
  display: flex;
  justify-content: space-between;

  &__maxContent {
    max-height: unset;
  }
  &__errorIcon {
    color: #f5222d;
  }
  &__code {
    margin: 0;
    color: color.$fg-neutral-2-default;
    font-size: 12px;
    font-weight: 400;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    word-break: break-all;
    font-family: SFMono;
  }
  &__copyBtnBox {
    padding: 6px 4px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;

    &:hover {
      background: #E2E4E9;
    }
  }
  &__copyBtn {
    display: flex;
  }

  &--disabled {
    cursor: not-allowed;

    .#{$baseClass} {
      &__copyBtn {
        pointer-events: none;
        color: gray;
      }
      &__copyBtnBox {
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
}
