@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-templateSelector';

.#{$baseClass} {
  margin-bottom: 20px;

  &__search {
    background-color: color.$bg-neutral-default;
    padding: 15px 20px;
    margin-bottom: 15px;
    border-radius: 6px;
  }
  &__templates {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  &__template {
    height: 170px;
    flex-direction: column;
    background-color: color.$primary-white;
    color: color.$fg-neutral-default;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    cursor: pointer;

    &--img {
      width: inherit;
      max-width: 60%;
      margin: auto;
    }
  }

  &__trafficsource {
    .#{$baseClass} {
      &__template {
        &--img {
          &--traffic_source_1,
          &--traffic_source_2,
          &--traffic_source_3,
          &--traffic_source_15,
          &--traffic_source_29 {
            max-width: 45%;
          }
          &--traffic_source_20,
          &--traffic_source_21,
          &--traffic_source_32,
          &--traffic_source_35,
          &--traffic_source_39,
          &--traffic_source_46 {
            max-width: 70%;
          }
					&--traffic_source_48 {
            max-width: 90%;
          }
					&--traffic_source_53 {
            max-width: 50%;
          }          
        }
      }
    }
  }
  &__offersource {
    .#{$baseClass} {
      &__template {
        &--img {
          &--offer_source_49,
          &--offer_source_57 {
            max-width: 50%;
          }
          &--offer_source_1,
          &--offer_source_46,
          &--offer_source_64,
          &--offer_source_68 {
            max-width: 45%;
          }
          &--offer_source_27,
          &--offer_source_47 {
            max-width: 30%;
          }
        }
      }
    }
  }
}
