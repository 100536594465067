@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffIconButton';

.#{$baseClass}.ant-btn {
  width: 32px;
  max-width: 40px;
  transition: none;
  box-shadow: none;

  &.#{$baseClass} {
    &--green {
      background: color.$bg-filter-default;

      &:hover {
        background: color.$bg-filter-hover;
      }
    }
    &--red {
      background: color.$bg-error-default;

      &:hover {
        background: color.$bg-error-hover;
      }
    }
    &--blue {
      background: transparent;
      svg {
        color: color.$st-info-default;
      }

      &:hover {
        background-color: color.$bg-neutral-2-hover;
      }
    }
    &--transparent {
      border: none;
      background-color: transparent;
      box-shadow: none;

      &:hover {
        background-color: color.$bg-neutral-2-hover;
      }
    }
  }
}
