/* Base Typography */
$family-primary: 'Open Sans', Arial, Helvetica, sans-serif;
$family-secondary: 'Noto Sans', Arial, Helvetica, sans-serif;
$family-table: 'Roboto', Arial, Helvetica, sans-serif;

$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$size-xs: 13px;
$size-sm: 15px;
$size-base: 16px;
$size-lg: 18px;
$size-xl: 20px;
$size-2xl: 22px;