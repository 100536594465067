@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-ffSelect';

.#{$baseClass}__select.ant-select {
  &--error .ant-select-selector {
    border-color: #f5222d !important;
  }
  &.ant-select-multiple {
    .ant-select-selection-item {
      background-color: color.$primary-blue;
      color: #fff;
      border-radius: 4px;
    }
    .ant-select-selection-item-remove,
    .ant-select-selection-item-remove:hover {
      color: #fff;
    }
  }
}
.ant-select-selection-search,
.ant-select-selection-item {
  @include text('body-2');
}
.ant-select-dropdown {
  max-width: 400px;

  .#{$baseClass} {
    &__createNewView .ant-select-item-option-content {
      color: color.$primary-blue;
    }
    &__selectAll,
    &__clearAll {
      border-bottom: 1px solid #e1e6eb;
    }
  }
}

.#{$baseClass} {
  .#{$baseClass}__select {
    width: 100%;
    height: 35px;
  }
}
