@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffDatepicker';

.ant-picker {
  &.#{$baseClass} {
    max-width: 303px;
    height: 35px;
    width: 100%;

    .ant-calendar-picker-input {
      border-color: #d9d9d9;

      &:hover {
        border-color: #40a9ff;
      }
    }
    .ant-calendar-range-picker-input {
      font-size: 104px;
    }
    .ant-picker-focused,
    .ant-picker:hover {
      border-color: #40a9ff;
    }
  }
}

.#{$baseClass} {
  &__dropdown {
    .ant-calendar-footer-btn {
      .ant-calendar-range-quick-selector {
        .ant-tag {
          margin-right: 3px;
          cursor: pointer;
        }
      }
    }
    .ant-picker-panel {
      width: 100%;
    }
    .ant-picker-date-panel {
      flex: 0.75;
    }
    .ant-picker-time-panel {
      flex: 0.25;
    }
  }
  &__timezone {
    width: auto;
    max-width: 125px;
  }
  &__footer {
    display: flex;
    flex-direction: column;
    margin: 5px 0px;
  }
  &__footerRow {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &__footerRowTitle {
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
  }
  &__footerRowBtn {
    color: color.$primary-blue;
    background: #e6f7ff;
    border-color: #91d5ff;
    cursor: pointer;
    height: 24px;
    font-size: 12px;
    line-height: initial;
    border: 1px solid #91d5ff;
    padding: 0px 5px;
    border-radius: 4px;
    width: max-content;
  }
}
