@use "@/uikit/styles/text" as *;
@use "@/uikit/styles/nav" as *;
@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffLayout';

.#{$baseClass} {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-x: hidden;

  // Custom scrollbar styles for webkit browsers (Chrome, Safari, Edge)
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: color.$slate-2;
  }

  ::-webkit-scrollbar-thumb {
    background: color.$slate-4;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: color.$slate-5;
  }

  // Firefox scrollbar (works from version 64+)
  @-moz-document url-prefix() {
    & {
      scrollbar-width: thin;
      scrollbar-color: #9cacba #f1f1f1;
    }
  }

  &__main {
    background-color: color.$bg-neutral-2-default;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__header {
    padding: 10px 0px;
    border-bottom: 1px solid color.$st-neutral-default;
  }

  &__sidebarHeader {
    border-bottom: 1px solid color.$st-neutral-default;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0px 10px;
    overflow: hidden;
    justify-content: space-between;
  }

  &__sidebarHeaderIconWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
  }

  &__sidebarHeaderTitle {
    @include nav('header-text');
  }

  &__logoIcon,
  &__expandIcon {
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    padding: 9px;
  }

  &__expandIcon {
    cursor: pointer;
    display: none;

    &:hover {
      @include nav('item-hover');
    }
  }

  &__logoIcon {
    background-color: color.$brand-red;
  }

  &__collapseSidebar {
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 10px;
    color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      @include nav('item-hover');
      color: unset;
    }
  }

  &__createNew {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 10px 0px 10px;
  }

  &__createPlusIcon {
    margin-top: -2px;
  }

  &__createNewButton {
    @include nav('create-new');
    display: flex;
    gap: 5px;

    span {
      margin-top: -1px;
    }

    &:hover {
      @include nav('create-new-hover');
    }

    &--collapsed {
      padding-right: 0px;
    }
  }

  &__sidebar {
    @include nav('sidebar');
    height: 100%;
    position: relative;
    transition: max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
    max-width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;

    // styles for when sidebar is collapsed
    &--collapsed {
      max-width: 60px;
      min-width: 60px;

      .#{$baseClass} {
        &__menuWrapper {
          @media screen and (max-height: 800px) {
            gap: 5px;
          }
        }

        &__menuItemTitle {
          opacity: 0;
        }

        &__menuItemIcon {
          display: none;
          visibility: hidden;
        }

        &__menuItem {
          white-space: nowrap;
          text-overflow: clip;
          overflow: hidden;
        }

        &__menuItemSubItems {
          display: none;
        }

        &__menuFooter {
          flex-direction: column;
        }

        &__sidebarHeaderTitle {
          visibility: hidden;
        }

        &__sidebarHeaderIconWrapper:hover {
          .#{$baseClass} {
            &__expandIcon {
              display: flex;
            }

            &__logoIcon {
              display: none;
            }
          }
        }
      }
    }

    &--expanded {
      .#{$baseClass} {
        &__expandIcon {
          display: none;
        }
      }
    }
  }

  &__menuFooter {
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 10px 0px;
    gap: 8px;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border-top: 1px solid color.$st-neutral-default;

    @media screen and (max-height: 800px) {
      gap: 0px;
    }
  }

  &__unreadChatCount {
    position: absolute;
    right: 0;
    top: 0;
    width: 15px;
    height: 15px;
    font-size: 9px;
    color: #fff;
    text-align: center;
    background-color: #ff033e;
    border-radius: 6px;
  }

  &__menuFooterItem {
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-radius: 10px;
    color: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      @include nav('item-hover');
      color: unset;
    }
  }

  &__menuWrapper {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0px 10px;
    overflow-y: overlay;
    overflow-x: hidden;
    height: calc(100vh - 200px);
  }

  &__menuItemIcon {
    position: absolute;
    right: 18px;
  }

  &__menuItemContent {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 36px;
    border-radius: 10px;
    padding: 2px 9px;
    user-select: none;
    color: #000000d9;

    &:hover {
      @include nav('item-hover');
    }

    // @TODO: quick patch, make a better solution later
    i {
      position: absolute;
    }

    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__menuItemSubItems {
    transition: max-height 0.3s ease, min-height 0.3s ease;
    overflow: hidden;
    list-style-type: none;
    padding-left: 0;
    margin-top: 0px;
  }

  &__menuSubItem {
    @include nav('menu-level-2');
    white-space: nowrap;
    display: flex;
    gap: 10px;

    &:hover {
      @include nav('item-hover');
    }

    &.active {
      @include nav('item-active');
    }

    &--disabled {
      @include nav('item-disabled');
    }
  }

  &__menuItemTitle {
    @include nav('menu-level-1');
    display: inline-block;
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;
  }

  &__menuItem {
    display: list-item;
    position: relative;
    text-decoration: none;

    &.active {
      .#{$baseClass} {
        &__menuItemContent {
          @include nav('item-active');
        }
      }
    }

    &--open {
      .#{$baseClass} {
        &__menuItemSubItems {
          padding: 6px 0px 0px 0px;
          display: grid;
          gap: 8px;
        }
      }

      >.#{$baseClass}__menuItemContent .#{$baseClass}__menuItemIcon {
        transform: rotate(180deg);
        display: none;
      }
    }

    &--closed {
      .#{$baseClass} {
        &__menuItemSubItems {
          max-height: 0px;
          min-height: 0px;
          padding: 0px !important;
          visibility: hidden;
        }
      }
    }

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  &__menuItemSecondLevel {
    padding-left: 30px;

    .#{$baseClass} {
      &__menuItemSubItems {
        @include nav('menu-level-3-children');
      }

      &__menuSubItem {
        @include nav('menu-level-3');
      }

      &__menuItemTitle {
        @include nav('menu-level-2-with-child');
      }
    }
  }

  // styles for popups
  &__menuItemPopup {
    @include nav('collapsed-popup');

    .#{$baseClass} {
      &__menuItemSecondLevel {
        padding-left: 0px;
        width: 100%;
      }

      &__menuItemTitle {
        margin-right: 40px;
      }

      &__menuItemSubItems {
        margin-bottom: 0px;
        display: grid;
        gap: 5px;
      }

      &__menuSubItem {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
