@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffTable';

.#{$baseClass} {
  border-collapse: collapse;
  font-size: 14px;
  min-width: 400px;
  border: 2px solid color.$st-neutral-default;

  &__headCell {
    padding: 12px 15px;
    color: color.$fg-neutral-default;

    &:not(:last-child) {
      border-right: 1px solid color.$st-neutral-default;
    }
  }
  &__rowCell {
    padding: 6px 15px;
    font-weight: 500;
    font-size: 14px;
    color: color.$fg-neutral-default;

    &:not(:last-child) {
      border-right: 1px solid color.$st-neutral-default;
    }
  }
  &__theadRow {
    background-color: color.$bg-neutral-2-default;
    text-align: left;
    border-bottom: 1px solid color.$st-neutral-default;
  }
  &__tbodyRow {
    border-bottom: 1px solid color.$st-neutral-default;
  }
  &__loadingIcon svg {
    fill: color.$primary-blue;
  }
}
