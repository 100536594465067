@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-createNewAssetModal';

.#{$baseClass} {
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  gap: 16px;

  &__asset {
    background-color: transparent;
    padding: 8px 24px;
    height: 120px;
    border-radius: 8px;
    border: 1px solid color.$st-neutral-default;
    box-shadow: 0 0 12px 0 rgba(100, 100, 111, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    font-weight: 600;
    color: color.$fg-neutral-default;
    cursor: pointer;

    &:hover {
      border-color: color.$slate-3;
      box-shadow: 0 0 12px 0 rgba(100, 100, 111, 0.2);
      background-color: color.$slate-2;
    }
  }
}
