@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffIcon';

.#{$baseClass} {
  &__xCircle {
    &:hover {
      color: #e9455e;
    }
  }
}
