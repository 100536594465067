// base Tailwind colors
$_black: rgb(0 0 0);
$_white: rgb(255 255 255);
$_slate-50: rgb(248 250 252);
$_slate-100: rgb(241 245 249);
$_slate-200: rgb(226 232 240);
$_slate-300: rgb(203 213 225);
$_slate-400: rgb(148 163 184);
$_slate-500: rgb(100 116 139);
$_slate-600: rgb(71 85 105);
$_slate-700: rgb(51 65 85);
$_slate-800: rgb(30 41 59);
$_slate-900: rgb(15 23 42);
$_slate-950: rgb(2 6 23);
$_gray-50: rgb(249 250 251);
$_gray-100: rgb(243 244 246);
$_gray-200: rgb(229 231 235);
$_gray-300: rgb(209 213 219);
$_gray-400: rgb(156 163 175);
$_gray-500: rgb(107 114 128);
$_gray-600: rgb(75 85 99);
$_gray-700: rgb(55 65 81);
$_gray-800: rgb(31 41 55);
$_gray-900: rgb(17 24 39);
$_gray-950: rgb(3 7 18);
$_zinc-50: rgb(250 250 250);
$_zinc-100: rgb(244 244 245);
$_zinc-200: rgb(228 228 231);
$_zinc-300: rgb(212 212 216);
$_zinc-400: rgb(161 161 170);
$_zinc-500: rgb(113 113 122);
$_zinc-600: rgb(82 82 91);
$_zinc-700: rgb(63 63 70);
$_zinc-800: rgb(39 39 42);
$_zinc-900: rgb(24 24 27);
$_zinc-950: rgb(9 9 11);
$_neutral-50: rgb(250 250 250);
$_neutral-100: rgb(245 245 245);
$_neutral-200: rgb(229 229 229);
$_neutral-300: rgb(212 212 212);
$_neutral-400: rgb(163 163 163);
$_neutral-500: rgb(115 115 115);
$_neutral-600: rgb(82 82 82);
$_neutral-700: rgb(64 64 64);
$_neutral-800: rgb(38 38 38);
$_neutral-900: rgb(23 23 23);
$_neutral-950: rgb(10 10 10);
$_stone-50: rgb(250 250 249);
$_stone-100: rgb(245 245 244);
$_stone-200: rgb(231 229 228);
$_stone-300: rgb(214 211 209);
$_stone-400: rgb(168 162 158);
$_stone-500: rgb(120 113 108);
$_stone-600: rgb(87 83 78);
$_stone-700: rgb(68 64 60);
$_stone-800: rgb(41 37 36);
$_stone-900: rgb(28 25 23);
$_stone-950: rgb(12 10 9);
$_red-50: rgb(254 242 242);
$_red-100: rgb(254 226 226);
$_red-200: rgb(254 202 202);
$_red-300: rgb(252 165 165);
$_red-400: rgb(248 113 113);
$_red-500: rgb(239 68 68);
$_red-600: rgb(220 38 38);
$_red-700: rgb(185 28 28);
$_red-800: rgb(153 27 27);
$_red-900: rgb(127 29 29);
$_red-950: rgb(69 10 10);
$_orange-50: rgb(255 247 237);
$_orange-100: rgb(255 237 213);
$_orange-200: rgb(254 215 170);
$_orange-300: rgb(253 186 116);
$_orange-400: rgb(251 146 60);
$_orange-500: rgb(249 115 22);
$_orange-600: rgb(234 88 12);
$_orange-700: rgb(194 65 12);
$_orange-800: rgb(154 52 18);
$_orange-900: rgb(124 45 18);
$_orange-950: rgb(67 20 7);
$_amber-50: rgb(255 251 235);
$_amber-100: rgb(254 243 199);
$_amber-200: rgb(253 230 138);
$_amber-300: rgb(252 211 77);
$_amber-400: rgb(251 191 36);
$_amber-500: rgb(245 158 11);
$_amber-600: rgb(217 119 6);
$_amber-700: rgb(180 83 9);
$_amber-800: rgb(146 64 14);
$_amber-900: rgb(120 53 15);
$_amber-950: rgb(69 26 3);
$_yellow-50: rgb(254 252 232);
$_yellow-100: rgb(254 249 195);
$_yellow-200: rgb(254 240 138);
$_yellow-300: rgb(253 224 71);
$_yellow-400: rgb(250 204 21);
$_yellow-500: rgb(234 179 8);
$_yellow-600: rgb(202 138 4);
$_yellow-700: rgb(161 98 7);
$_yellow-800: rgb(133 77 14);
$_yellow-900: rgb(113 63 18);
$_yellow-950: rgb(66 32 6);
$_lime-50: rgb(247 254 231);
$_lime-100: rgb(236 252 203);
$_lime-200: rgb(217 249 157);
$_lime-300: rgb(190 242 100);
$_lime-400: rgb(163 230 53);
$_lime-500: rgb(132 204 22);
$_lime-600: rgb(101 163 13);
$_lime-700: rgb(77 124 15);
$_lime-800: rgb(63 98 18);
$_lime-900: rgb(54 83 20);
$_lime-950: rgb(26 46 5);
$_green-50: rgb(240 253 244);
$_green-100: rgb(220 252 231);
$_green-200: rgb(187 247 208);
$_green-300: rgb(134 239 172);
$_green-400: rgb(74 222 128);
$_green-500: rgb(34 197 94);
$_green-600: rgb(22 163 74);
$_green-700: rgb(21 128 61);
$_green-800: rgb(22 101 52);
$_green-900: rgb(20 83 45);
$_green-950: rgb(5 46 22);
$_emerald-50: rgb(236 253 245);
$_emerald-100: rgb(209 250 229);
$_emerald-200: rgb(167 243 208);
$_emerald-300: rgb(110 231 183);
$_emerald-400: rgb(52 211 153);
$_emerald-500: rgb(16 185 129);
$_emerald-600: rgb(5 150 105);
$_emerald-700: rgb(4 120 87);
$_emerald-800: rgb(6 95 70);
$_emerald-900: rgb(6 78 59);
$_emerald-950: rgb(2 44 34);
$_teal-50: rgb(240 253 250);
$_teal-100: rgb(204 251 241);
$_teal-200: rgb(153 246 228);
$_teal-300: rgb(94 234 212);
$_teal-400: rgb(45 212 191);
$_teal-500: rgb(20 184 166);
$_teal-600: rgb(13 148 136);
$_teal-700: rgb(15 118 110);
$_teal-800: rgb(17 94 89);
$_teal-900: rgb(19 78 74);
$_teal-950: rgb(4 47 46);
$_cyan-50: rgb(236 254 255);
$_cyan-100: rgb(207 250 254);
$_cyan-200: rgb(165 243 252);
$_cyan-300: rgb(103 232 249);
$_cyan-400: rgb(34 211 238);
$_cyan-500: rgb(6 182 212);
$_cyan-600: rgb(8 145 178);
$_cyan-700: rgb(14 116 144);
$_cyan-800: rgb(21 94 117);
$_cyan-900: rgb(22 78 99);
$_cyan-950: rgb(8 51 68);
$_sky-50: rgb(240 249 255);
$_sky-100: rgb(224 242 254);
$_sky-200: rgb(186 230 253);
$_sky-300: rgb(125 211 252);
$_sky-400: rgb(56 189 248);
$_sky-500: rgb(14 165 233);
$_sky-600: rgb(2 132 199);
$_sky-700: rgb(3 105 161);
$_sky-800: rgb(7 89 133);
$_sky-900: rgb(12 74 110);
$_sky-950: rgb(8 47 73);
$_blue-50: rgb(239 246 255);
$_blue-100: rgb(219 234 254);
$_blue-200: rgb(191 219 254);
$_blue-300: rgb(147 197 253);
$_blue-400: rgb(96 165 250);
$_blue-500: rgb(59 130 246);
$_blue-600: rgb(37 99 235);
$_blue-700: rgb(29 78 216);
$_blue-800: rgb(30 64 175);
$_blue-900: rgb(30 58 138);
$_blue-950: rgb(23 37 84);
$_indigo-50: rgb(238 242 255);
$_indigo-100: rgb(224 231 255);
$_indigo-200: rgb(199 210 254);
$_indigo-300: rgb(165 180 252);
$_indigo-400: rgb(129 140 248);
$_indigo-500: rgb(99 102 241);
$_indigo-600: rgb(79 70 229);
$_indigo-700: rgb(67 56 202);
$_indigo-800: rgb(55 48 163);
$_indigo-900: rgb(49 46 129);
$_indigo-950: rgb(30 27 75);
$_violet-50: rgb(245 243 255);
$_violet-100: rgb(237 233 254);
$_violet-200: rgb(221 214 254);
$_violet-300: rgb(196 181 253);
$_violet-400: rgb(167 139 250);
$_violet-500: rgb(139 92 246);
$_violet-600: rgb(124 58 237);
$_violet-700: rgb(109 40 217);
$_violet-800: rgb(91 33 182);
$_violet-900: rgb(76 29 149);
$_violet-950: rgb(46 16 101);
$_purple-50: rgb(250 245 255);
$_purple-100: rgb(243 232 255);
$_purple-200: rgb(233 213 255);
$_purple-300: rgb(216 180 254);
$_purple-400: rgb(192 132 252);
$_purple-500: rgb(168 85 247);
$_purple-600: rgb(147 51 234);
$_purple-700: rgb(126 34 206);
$_purple-800: rgb(107 33 168);
$_purple-900: rgb(88 28 135);
$_purple-950: rgb(59 7 100);
$_fuchsia-50: rgb(253 244 255);
$_fuchsia-100: rgb(250 232 255);
$_fuchsia-200: rgb(245 208 254);
$_fuchsia-300: rgb(240 171 252);
$_fuchsia-400: rgb(232 121 249);
$_fuchsia-500: rgb(217 70 239);
$_fuchsia-600: rgb(192 38 211);
$_fuchsia-700: rgb(162 28 175);
$_fuchsia-800: rgb(134 25 143);
$_fuchsia-900: rgb(112 26 117);
$_fuchsia-950: rgb(74 4 78);
$_pink-50: rgb(253 242 248);
$_pink-100: rgb(252 231 243);
$_pink-200: rgb(251 207 232);
$_pink-300: rgb(249 168 212);
$_pink-400: rgb(244 114 182);
$_pink-500: rgb(236 72 153);
$_pink-600: rgb(219 39 119);
$_pink-700: rgb(190 24 93);
$_pink-800: rgb(157 23 77);
$_pink-900: rgb(131 24 67);
$_pink-950: rgb(80 7 36);
$_rose-50: rgb(255 241 242);
$_rose-100: rgb(255 228 230);
$_rose-200: rgb(254 205 211);
$_rose-300: rgb(253 164 175);
$_rose-400: rgb(251 113 133);
$_rose-500: rgb(244 63 94);
$_rose-600: rgb(225 29 72);
$_rose-700: rgb(190 18 60);
$_rose-800: rgb(159 18 57);
$_rose-900: rgb(136 19 55);
$_rose-950: rgb(76 5 25);

$_success-green: rgb(34 197 94);
$_warning-orange: rgb(249 115 22);
$_warning-red: rgb(239 68 68);

$_nav_icon_fill: #d7e0ff;
$_nav_icon_stroke: #48508b;

////////////////////////////
// Public colors
////////////////////////////

:root {
    --primary-white: #{$_white};
    --primary-blue: #{$_blue-500};
    --primary-blue-hover: #{$_blue-600};
    --brand-red: #da365b;
    --delete-red: #{$_red-600};
    --filter-green: #{$_teal-500};
    --success-green: #{$_green-600};
    --warning-orange: #{$_orange-500};
    --warning-red: #{$_red-500};

    --slate-1: #{$_slate-50};
    --slate-2: #{$_slate-100};
    --slate-3: #{$_slate-200};
    --slate-4: #{$_slate-300};
    --slate-5: #{$_slate-400};
    --slate-6: #{$_slate-500};
    --slate-7: #{$_slate-600};
    --slate-8: #{$_slate-700};
    --slate-9: #{$_slate-800};
    --slate-10: #{$_slate-900};

    --gray-1: #{$_gray-50};
    --gray-2: #{$_gray-100};
    --gray-3: #{$_gray-200};
    --gray-4: #{$_gray-300};
    --gray-5: #{$_gray-400};
    --gray-6: #{$_gray-500};
    --gray-7: #{$_gray-600};
    --gray-8: #{$_gray-700};
    --gray-9: #{$_gray-800};
    --gray-10: #{$_gray-900};

    --dark-blue: #{$_blue-800};

    --app-background: #{$_slate-100};
    --default-border: #{$_slate-200};
    --table-header: #{$_slate-900};
    --table-border: #{$_slate-300};
    --table-row-hover: #{$_blue-50};
    --table-icon-hover: #{$_indigo-200};

    --icon-primary: #{$_slate-50};
    --icon-primary-hover: #{$_slate-100};

    --icon-secondary: #{$_slate-100};
    --icon-secondary-hover: #{$_slate-200};

    --icon-danger: #{$_red-500};
    --icon-danger-hover: #{$_red-600};

    --icon-tertiary: #{$_slate-500};
    --icon-tertiary-hover: #{$_slate-600};

    --icon-purple: #{$_purple-500};
    --icon-purple-hover: #{$_purple-600};

    --nav-background: #{$_slate-100};
    --nav-button-hover: #{$_slate-200};
    --nav-button-active: #{$_slate-300};
    --nav-icon-fill: #{$_slate-100};
    --nav-icon-stroke: #{$_slate-900};
    --nav-header-bg: #{$_white};
    --nav-icon-fill: #{$_nav_icon_fill};
    --nav-icon-stroke: #{$_nav_icon_stroke};

    --button-ghost: #{$_blue-500};
    --button-ghost-hover: #{$_blue-700};

    --slider-handle: #{$_blue-500};
    --slider-rail: #{$_slate-200};
    --slider-track: #{$_blue-400};

    --metric-visits: #{$_slate-900};
    --metric-landerviews: #{$_slate-900};
    --metric-landerclicks: #{$_fuchsia-500};
    --metric-offerviews: #{$_success-green};
    --metric-conversions: #{$_fuchsia-500};
    --metric-revenue: #{$_success-green};
    --metric-cost: #{$_warning-orange};
    --metric-pl-positive: #{$_warning-orange};
    --metric-pl-negative: #{$_warning-red};
    --metric-roi-positive: #{$_warning-orange};

    --bg-info-default: #{$_blue-500};
    --bg-info-hover: #{$_blue-600};
    --bg-info-active: #{$_blue-700};
    --bg-info-disabled: #{$_slate-300};
    --bg-info-2-default: #{$_blue-50};
    --bg-info-2-hover: #{$_blue-400};
    --bg-info-2-active: #{$_blue-500};
    --bg-info-2-disabled: #{$_slate-300};
    --bg-neutral-default: #{$_white};
    --bg-neutral-hover: #{$_gray-50};
    --bg-neutral-active: #{$_gray-200};
    --bg-neutral-disabled: #{$_gray-100};
    --bg-neutral-2-default: #{$_gray-100};
    --bg-neutral-2-hover: #{$_gray-200};
    --bg-neutral-2-active: #{$_gray-300};
    --bg-neutral-2-disabled: #{$_gray-100};
    --bg-neutral-3-default: #{$_gray-200};
    --bg-neutral-3-hover: #{$_gray-300};
    --bg-neutral-3-active: #{$_gray-800};
    --bg-neutral-3-disabled: #{$_gray-500};
    --bg-error-default: #{$_red-500};
    --bg-error-hover: #{$_red-600};
    --bg-error-active: #{$_red-700};
    --bg-error-disabled: #{$_slate-300};
    --bg-error-2-default: #{$_red-300};
    --bg-error-2-hover: #{$_red-400};
    --bg-error-2-active: #{$_red-500};
    --bg-error-2-disabled: #{$_slate-300};
    --bg-warning-default: #{$_orange-500};
    --bg-warning-hover: #{$_orange-600};
    --bg-warning-active: #{$_orange-700};
    --bg-warning-disabled: #{$_slate-300};
    --bg-warning-2-default: #{$_orange-300};
    --bg-warning-2-hover: #{$_orange-400};
    --bg-warning-2-active: #{$_orange-500};
    --bg-warning-2-disabled: #{$_slate-300};
    --bg-success-default: #{$_green-500};
    --bg-success-hover: #{$_green-600};
    --bg-success-active: #{$_green-700};
    --bg-success-disabled: #{$_slate-300};
    --bg-success-2-default: #{$_green-300};
    --bg-success-2-hover: #{$_green-400};
    --bg-success-2-active: #{$_green-500};
    --bg-success-2-disabled: #{$_slate-300};
    --bg-filter-default: #{$_teal-500};
    --bg-filter-hover: #{$_teal-600};

    --fg-info-default: #{$_white};
    --fg-info-2-default: #{$_blue-700};
    --fg-info-hover: #{$_white};
    --fg-info-active: #{$_white};
    --fg-info-disabled: #{$_slate-500};
    --fg-neutral-default: #{$_slate-600};
    --fg-neutral-hover: #{$_slate-800};
    --fg-neutral-active: #{$_slate-900};
    --fg-neutral-disabled: #{$_slate-500};
    --fg-neutral-2-default: #{$_slate-600};
    --fg-neutral-2-hover: #{$_slate-700};
    --fg-neutral-2-active: #{$_slate-800};
    --fg-neutral-2-disabled: #{$_slate-500};
    --fg-neutral-3-default: #{$_slate-500};
    --fg-neutral-3-hover: #{$_slate-600};
    --fg-neutral-3-active: #{$_slate-700};
    --fg-neutral-3-disabled: #{$_slate-500};
    --fg-error-default: #{$_red-100};
    --fg-error-hover: #{$_red-100};
    --fg-error-active: #{$_red-200};
    --fg-error-disabled: #{$_slate-500};
    --fg-warning-default: #{$_orange-50};
    --fg-warning-hover: #{$_orange-100};
    --fg-warning-active: #{$_orange-200};
    --fg-warning-disabled: #{$_slate-500};

    --st-info-default: #{$_blue-500};
    --st-info-hover: #{$_blue-600};
    --st-info-active: #{$_blue-700};
    --st-info-disabled: #{$_slate-400};
    --st-neutral-default: #{$_gray-200};
    --st-neutral-hover: #{$_gray-300};
    --st-neutral-active: #{$_gray-400};
    --st-neutral-disabled: #{$_gray-400};
    --st-neutral-2-default: #{$_gray-300};
    --st-neutral-2-hover: #{$_gray-300};
    --st-neutral-2-active: #{$_gray-500};
    --st-neutral-2-disabled: #{$_gray-500};
    --st-neutral-3-default: #{$_gray-400};
    --st-neutral-3-hover: #{$_gray-500};
    --st-neutral-3-active: #{$_gray-600};
    --st-neutral-3-disabled: #{$_gray-600};         
    --st-error-default: #{$_red-500};
    --st-error-hover: #{$_red-600};
    --st-error-active: #{$_red-700};
    --st-error-disabled: #{$_slate-400};
    --st-warning-default: #{$_orange-500};
    --st-warning-hover: #{$_orange-600};
    --st-warning-active: #{$_orange-700};
    --st-warning-disabled: #{$_slate-400};
}

:root.dark-mode {
    --primary-blue: #{$_blue-400};
    --primary-blue-hover: #{$_blue-500}; 

    --app-background: #{$_slate-900};

    --bg-neutral-default: #{$_gray-950};
    --bg-neutral-hover: #{$_gray-900};
    --bg-neutral-2-default: #{$_slate-800};
    --bg-neutral-3-default: #{$_slate-700};

    --st-neutral-default: #{$_slate-400};

    --fg-info-default: #{$_gray-50};
    --fg-neutral-default: #{$_gray-50};
    --fg-neutral-2-default: #{$_gray-50};
}
