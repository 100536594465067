@use '@/uikit/styles/colors.scss' as color;
@use '@/uikit/styles/text.scss' as *;
@use 'sass:map';

$baseClass: 'c-ffButton';

@mixin primary($disabled) {
  background-color: color.$bg-info-default;
  border-color: color.$st-info-default;
  color: color.$fg-info-default;

  &:hover {
    background-color: color.$bg-info-hover;
    border-color: color.$st-info-hover;
    color: color.$fg-info-hover;
  }
  @if $disabled {
    background-color: color.$bg-info-disabled;
    border-color: transparent;
    color: color.$fg-info-disabled;
    cursor: not-allowed;
    pointer-events: none;

    .#{$baseClass}__icon {
      color: color.$fg-info-disabled;
    }
  }
}

@mixin primary-danger($disabled) {
  background-color: color.$bg-error-default;
  border-color: color.$st-error-default;
  color: color.$fg-error-default;

  &:hover {
    background-color: color.$bg-error-hover;
    border-color: color.$st-error-hover;
    color: color.$fg-error-hover;
  }
  @if $disabled {
    background-color: color.$bg-error-disabled;
    border-color: transparent;
    color: color.$fg-error-disabled;
    cursor: not-allowed;
    pointer-events: none;

    .#{$baseClass}__icon {
      color: color.$fg-error-disabled;
    }
  }
}

@mixin primary-warning($disabled) {
  background-color: color.$bg-warning-default;
  border-color: color.$st-warning-default;
  color: color.$fg-warning-default;

  &:hover {
    background-color: color.$bg-warning-hover;
    border-color: color.$st-warning-hover;
    color: color.$fg-warning-hover;
  }
  @if $disabled {
    background-color: color.$bg-warning-disabled;
    border-color: transparent;
    color: color.$fg-warning-disabled;
    cursor: not-allowed;
    pointer-events: none;

    .#{$baseClass}__icon {
      color: color.$fg-warning-disabled;
    }
  }
}

@mixin tertiary-danger($disabled) {
  background-color: transparent;
  border-color: color.$st-error-default;
  color: color.$bg-error-default;

  &:hover {
    background-color: color.$bg-error-hover;
    border-color: color.$st-error-hover;
    color: color.$fg-error-hover;

    .#{$baseClass}__icon {
      color: color.$fg-error-hover;
    }
  }
  @if $disabled {
    background-color: color.$bg-error-disabled;
    border-color: transparent;
    color: color.$fg-error-disabled;
    cursor: not-allowed;
    pointer-events: none;

    .#{$baseClass}__icon {
      color: color.$fg-error-disabled;
    }
  }
}

@mixin tertiary($active, $disabled) {
  background-color: color.$bg-neutral-3-default;
  border-color: color.$bg-neutral-3-default;
  color: color.$fg-neutral-3-default;

  &:hover {
    background-color: color.$bg-info-hover;
    border-color: color.$st-info-hover;
    color: color.$fg-info-hover;

    .#{$baseClass}__icon {
      color: color.$fg-info-hover;
    }
  }
  @if $active {
    background-color: color.$bg-info-active;
    border-color: color.$st-info-active;
    color: color.$fg-info-active;

    .#{$baseClass}__icon {
      color: color.$fg-info-active;
    }
  }
  @if $disabled {
    background-color: color.$bg-neutral-disabled;
    border-color: transparent;
    color: color.$fg-neutral-disabled;
    cursor: not-allowed;
    pointer-events: none;

    .#{$baseClass}__icon {
      color: color.$fg-neutral-disabled;
    }
  }
}

@mixin dangerSkeleton {
  background-color: color.$bg-error-default;
  border-color: color.$st-error-default;
  color: color.$fg-error-default;
  position: relative;

  &:hover {
    background-color: color.$bg-error-hover;
    border-color: color.$st-error-hover;
    color: color.$fg-error-hover;
  }
  .#{$baseClass}__skeleton {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 70%), transparent;
    background-repeat: repeat-y;
    background-size: 200px 500px;
    background-position: -200px 0;
    animation: shine 1s infinite;
    animation-timing-function: linear;
  }
  span.#{$baseClass}__text {
    visibility: visible;
  }

  @keyframes shine {
    to {
      background-position:
        100% 0,
        0 0;
    }
  }
}

@mixin gray {
  background-color: color.$bg-neutral-default;
  border-color: color.$st-neutral-default;
  color: color.$fg-neutral-default;

  &:hover {
    background-color: color.$bg-neutral-hover;
    border-color: color.$st-neutral-hover;
    color: color.$fg-neutral-hover;
  }
}

@mixin ghost {
  background-color: transparent;
  border-color: transparent;
  color: color.$bg-info-default;
  box-shadow: none;

  .#{$baseClass}__icon {
    color: color.$bg-info-default;
  }
  &:hover {
    background-color: color.$bg-neutral-3-default;
    border-color: transparent;
    color: color.$bg-info-hover;

    .#{$baseClass}__icon {
      color: color.$bg-info-hover;
    }
  }
}

@mixin brand-red {
  background-color: color.$brand-red;
  border-color: color.$brand-red;
  color: color.$primary-white;

  &:hover {
    background-color: #d60939;
    border-color: color.$brand-red;
    color: color.$primary-white;
  }
}

.ant-btn.ant-btn-default.#{$baseClass} {
  min-width: 70px;
  border: 1.5px solid color.$st-info-default;
  cursor: pointer;
  transition: all 0ms;
  padding: 16px 20px;
  height: 35px;
  box-shadow: none;
  position: relative;
  @include text('body-2');

  &--minimum {
    width: min-content;
  }
  &--primary {
    @include primary(false);

    &.#{$baseClass}--disabled {
      @include primary(true);
    }
  }
  &--primaryDanger {
    @include primary-danger(false);

    &.#{$baseClass}--disabled {
      @include primary-danger(true);
    }
  }
  &--primaryWarning {
    @include primary-warning(false);

    &.#{$baseClass}--disabled {
      @include primary-warning(true);
    }
  }
  &--tertiaryDanger {
    @include tertiary-danger(false);

    &.#{$baseClass}--disabled {
      @include tertiary-danger(true);
    }
  }
  &--tertiary {
    @include tertiary(false, false);

    &.#{$baseClass}--disabled {
      @include tertiary(false, true);
    }
    &.#{$baseClass}--active {
      @include tertiary(true, false);
    }
  }
  &--dangerSkeleton {
    @include dangerSkeleton();
  }
  &--gray {
    @include gray();
  }
  &--ghost {
    @include ghost();
  }
  &--brandRed {
    @include brand-red;
  }

  &--loading {
    .#{$baseClass}__text,
    .#{$baseClass}__icon {
      visibility: hidden;
    }
    .#{$baseClass}__loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
