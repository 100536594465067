@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffText';

.#{$baseClass} {
    &__span {
        &--title-3 {
            font-size: 18px;
            font-weight: 600;
        }
        &--body-1 {
            font-size: 16px;
            font-weight: 600;
        }
        &--body-2-strong {
            font-size: 14px;
            font-weight: 700;
        }
        &--body-2 {
            font-size: 14px;
            font-weight: 500;
        }
    }
    &__p {
        &--title-3 {
            font-size: 18px;
            font-weight: 600;
        }
        &--body-1 {
            font-size: 16px;
            font-weight: 600;
        }
        &--body-2-strong {
            font-size: 14px;
            font-weight: 700;
        }
        &--body-2 {
            font-size: 14px;
            font-weight: 500;
        }
    }
}