@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-ffTooltip';

.#{$baseClass} {
  &__tooltip {
    color: color.$primary-blue !important;
    cursor: pointer;
  }
  &__tooltipOverlay {
    .ant-tooltip-inner {
      background-color: #fff;
      padding: 18px;
      color: #465565;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: #fff;
    }
  }
}
