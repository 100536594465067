@use '@/uikit/styles/colors.scss' as color;
@use '@/uikit/styles/base_typography.scss' as font;
@use 'sass:map';

$baseClass: 'c-errorFallback';

.#{$baseClass} {
  &__logo {
    width: 100px;
  }
  &__errorText {
    font-weight: 600;
    color: #5d6d88;
    font-size: 16px;
    margin-top: 20px;
  }
}
