@use '@/uikit/styles/colors.scss' as color;
@use '@/uikit/styles/base_typography.scss' as font;
@use 'sass:map';

$baseClass: 'c-partners';

.#{$baseClass} {
  width: 100%;
  height: 100%;
  background-color: #fff;

  &__sidebarHeader {
    display: none;
  }
  &__sidebarContent {
    overflow-y: unset;
  }
  &__header {
    display: grid;
    grid-template-columns: 200px 1fr;
    border-bottom: 1px solid color.$default-border;
    height: 80px;
  }
  &__categories {
    border-right: 1px solid color.$default-border;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: font.$weight-bold;
  }
  &__partnersMarketplace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    gap: 8px;
  }
  &__partnersMarketplaceLogo {
    width: 50px;
  }
  &__partnersMarketplaceSearch {
    justify-self: flex-start;
    width: 200px;
  }
  &__body {
    display: grid;
    grid-template-columns: 200px 1fr;
    height: 100%;
  }
  &__categoriesList {
    border-right: 1px solid color.$default-border;
  }
  &__categoryListItem {
    cursor: pointer;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid color.$default-border;

    &--active {
      border-right: 2px solid color.$warning-red;
    }
    &:hover {
      background-color: color.$slate-2;
    }
  }
  &__partnersList {
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
  }
  &__partnerListItem {
    display: flex;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background-color: hsla(0, 0%, 89.8%, 0.0901960784);
    display: flex;
    margin-bottom: 25px;

    &:hover {
      box-shadow: 0px 0px 3px 2px rgba(255, 0, 0, 0.5);
    }
  }
  &__partnerListItemImage {
    flex: 0.25 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 150px;
    }
  }
  &__partnerListItemContent {
    flex: 0.75 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
    flex-direction: column;
    padding: 25px 10px;
    max-width: 700px;
  }
  &__partnerListItemTitle {
    font-weight: font.$weight-bold;
    font-size: font.$size-2xl;
  }
  &__partnerListItemPromoDescription {
    font-weight: font.$weight-bold;
  }
  &__descriptionContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
    height: 100%;
  }
  &__descriptionContentHeader {
    font-weight: font.$weight-bold;
    font-size: font.$size-2xl;
    border-bottom: 1px solid color.$default-border;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__descriptionContentImage {
    margin: 0px auto;
    
    img {
      width: 140px;
    }
  }
  &__descriptionContentText {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    ul {
      margin-bottom: 0;
      margin-top: -10px;

    }
  }
  &__descriptionContentCta {
    margin: 0px auto;
  }
}
