$primary-white: var(--primary-white);
$primary-blue: var(--primary-blue);
$primary-info-hover: var(--primary-info-hover);
$brand-red: var(--brand-red);
$delete-red: var(--delete-red);
$filter-green: var(--filter-green);
$success-green: var(--success-green);
$warning-orange: var(--warning-orange);
$warning-red: var(--warning-red);

$slate-1: var(--slate-1);
$slate-2: var(--slate-2);
$slate-3: var(--slate-3);
$slate-4: var(--slate-4);
$slate-5: var(--slate-5);
$slate-6: var(--slate-6);
$slate-7: var(--slate-7);
$slate-8: var(--slate-8);
$slate-9: var(--slate-9);
$slate-10: var(--slate-10);

$gray-1: var(--gray-1);
$gray-2: var(--gray-2);
$gray-3: var(--gray-3);
$gray-4: var(--gray-4);
$gray-5: var(--gray-5);
$gray-6: var(--gray-6);
$gray-7: var(--gray-7);
$gray-8: var(--gray-8);
$gray-9: var(--gray-9);
$gray-10: var(--gray-10);

$dark-blue: var(--dark-blue);

$app-background: var(--app-background);
$default-border: var(--default-border);
$table-header: var(--table-header);
$table-border: var(--table-border);
$table-row-hover: var(--table-row-hover);
$table-icon-hover: var(--table-icon-hover);

$metric-visits: var(--metric-visits);
$metric-landerviews: var(--metric-landerviews);
$metric-landerclicks: var(--metric-landerclicks);
$metric-offerviews: var(--metric-offerviews);
$metric-conversions: var(--metric-conversions);
$metric-revenue: var(--metric-revenue);
$metric-cost: var(--metric-cost);
$metric-pl-positive: var(--metric-pl-positive);
$metric-pl-negative: var(--metric-pl-negative);
$metric-roi-positive: var(--metric-roi-positive);
$metric-visits-bg: var(--metric-visits-bg);

$sidebar-tab-active-icon: var(--sidebar-tab-active-icon);
$sidebar-tab-active-bg: var(--sidebar-tab-active-bg);
$sidebar-tab-active-text: var(--sidebar-tab-active-text);

$button-ghost: var(--button-ghost);
$button-ghost-hover: var(--button-ghost-hover);

$icon-primary: var(--icon-primary);
$icon-primary-hover: var(--icon-primary-hover);

$icon-secondary: var(--icon-secondary);
$icon-secondary-hover: var(--icon-secondary-hover);

$icon-danger: var(--icon-danger);
$icon-danger-hover: var(--icon-danger-hover);

$icon-tertiary: var(--icon-tertiary);
$icon-tertiary-hover: var(--icon-tertiary-hover);

$icon-purple: var(--icon-purple);
$icon-purple-hover: var(--icon-purple-hover);

$nav-background: var(--nav-background);
$nav-button-hover: var(--nav-button-hover);
$nav-button-active: var(--nav-button-active);
$nav-icon-fill: var(--nav-icon-fill);
$nav-icon-stroke: var(--nav-icon-stroke);
$nav-header-bg: var(--nav-header-bg);

$bg-info-default: var(--bg-info-default);
$bg-info-hover: var(--bg-info-hover);
$bg-info-active: var(--bg-info-active);
$bg-info-disabled: var(--bg-info-disabled);
$bg-info-2-default: var(--bg-info-2-default);
$bg-info-2-hover: var(--bg-info-2-hover);
$bg-info-2-active: var(--bg-info-2-active);
$bg-info-2-disabled: var(--bg-info-2-disabled);
$bg-neutral-default: var(--bg-neutral-default);
$bg-neutral-hover: var(--bg-neutral-hover);
$bg-neutral-active: var(--bg-neutral-active);
$bg-neutral-disabled: var(--bg-neutral-disabled);
$bg-neutral-2-default: var(--bg-neutral-2-default);
$bg-neutral-2-hover: var(--bg-neutral-2-hover);
$bg-neutral-2-active: var(--bg-neutral-2-active);
$bg-neutral-2-disabled: var(--bg-neutral-2-disabled);
$bg-neutral-3-default: var(--bg-neutral-3-default);
$bg-neutral-3-hover: var(--bg-neutral-3-hover);
$bg-neutral-3-active: var(--bg-neutral-3-active);
$bg-neutral-3-disabled: var(--bg-neutral-3-disabled);
$bg-error-default: var(--bg-error-default);
$bg-error-hover: var(--bg-error-hover);
$bg-error-active: var(--bg-error-active);
$bg-error-disabled: var(--bg-error-disabled);
$bg-error-2-default: var(--bg-error-2-default);
$bg-error-2-hover: var(--bg-error-2-hover);
$bg-error-2-active: var(--bg-error-2-active);
$bg-error-2-disabled: var(--bg-error-2-disabled);
$bg-warning-default: var(--bg-warning-default);
$bg-warning-hover: var(--bg-warning-hover);
$bg-warning-active: var(--bg-warning-active);
$bg-warning-disabled: var(--bg-warning-disabled);
$bg-warning-2-default: var(--bg-warning-2-default);
$bg-warning-2-hover: var(--bg-warning-2-hover);
$bg-warning-2-active: var(--bg-warning-2-active);
$bg-warning-2-disabled: var(--bg-warning-2-disabled);
$bg-success-default: var(--bg-success-default);
$bg-success-hover: var(--bg-success-hover);
$bg-success-active: var(--bg-success-active);
$bg-success-disabled: var(--bg-success-disabled);
$bg-success-2-default: var(--bg-success-2-default);
$bg-success-2-hover: var(--bg-success-2-hover);
$bg-success-2-active: var(--bg-success-2-active);
$bg-success-2-disabled: var(--bg-success-2-disabled);
$bg-filter-default: var(--bg-filter-default);
$bg-filter-hover: var(--bg-filter-hover);

$fg-info-default: var(--fg-info-default);
$fg-info-2-default: var(--fg-info-2-default);
$fg-info-hover: var(--fg-info-hover);
$fg-info-active: var(--fg-info-active);
$fg-info-disabled: var(--fg-info-disabled);
$fg-neutral-default: var(--fg-neutral-default);
$fg-neutral-hover: var(--fg-neutral-hover);
$fg-neutral-active: var(--fg-neutral-active);
$fg-neutral-disabled: var(--fg-neutral-disabled);
$fg-neutral-2-default: var(--fg-neutral-2-default);
$fg-neutral-2-hover: var(--fg-neutral-2-hover);
$fg-neutral-2-active: var(--fg-neutral-2-active);
$fg-neutral-2-disabled: var(--fg-neutral-2-disabled);
$fg-neutral-3-default: var(--fg-neutral-3-default);
$fg-neutral-3-hover: var(--fg-neutral-3-hover);
$fg-neutral-3-active: var(--fg-neutral-3-active);
$fg-neutral-3-disabled: var(--fg-neutral-3-disabled);
$fg-error-default: var(--fg-error-default);
$fg-error-hover: var(--fg-error-hover);
$fg-error-active: var(--fg-error-active);
$fg-error-disabled: var(--fg-error-disabled);
$fg-warning-default: var(--fg-warning-default);
$fg-warning-hover: var(--fg-warning-hover);
$fg-warning-active: var(--fg-warning-active);
$fg-warning-disabled: var(--fg-warning-disabled);


$st-info-default: var(--st-info-default);
$st-info-hover: var(--st-info-hover);
$st-info-active: var(--st-info-active);
$st-info-disabled: var(--st-info-disabled);
$st-neutral-default: var(--st-neutral-default);
$st-neutral-hover: var(--st-neutral-hover);
$st-neutral-active: var(--st-neutral-active);
$st-neutral-disabled: var(--st-neutral-disabled);
$st-neutral-2-default: var(--st-neutral-2-default);
$st-neutral-2-hover: var(--st-neutral-2-hover);
$st-neutral-2-active: var(--st-neutral-2-active);
$st-neutral-2-disabled: var(--st-neutral-2-disabled);
$st-neutral-3-default: var(--st-neutral-3-default);
$st-neutral-3-hover: var(--st-neutral-3-hover);
$st-neutral-3-active: var(--st-neutral-3-active);
$st-neutral-3-disabled: var(--st-neutral-3-disabled);
$st-error-default: var(--st-error-default);
$st-error-hover: var(--st-error-hover);
$st-error-active: var(--st-error-active);
$st-error-disabled: var(--st-error-disabled);
$st-warning-default: var(--st-warning-default);
$st-warning-hover: var(--st-warning-hover);
$st-warning-active: var(--st-warning-active);
$st-warning-disabled: var(--st-warning-disabled);
