@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-ffField';

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  gap: 6px;
  
  &--row {
    flex-direction: row;
  }
  &--block {
    width: 100%;

    &.#{$baseClass}--row {
      .#{$baseClass}__childContent {
        flex-grow: 1;
      }
    }
  }
  &__label {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  &__labelText {
    @include text('body-2-strong');
  }
  &__childContent {
    display: flex;
    gap: 6px;
    align-items: center;

    > div {
      width: 100%;
    }
  }
  &__error {
    color: #f5222d;
    margin-top: 5px;
  }
}
