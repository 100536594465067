@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-trafficSourceForm';

.#{$baseClass} {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__trackingFieldCol {
    width: fit-content;
    color: #5186e1;
    font-weight: 600;
    width: 120px;

    &--disabled {
      color: #00000040;
    }
  }
  &__funnelfluxTokenInput input {
    color: #5186e1;
  }
}
