@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/text.scss" as *;
@use "sass:map";

$baseClass: 'c-pageSlider';

.#{$baseClass} {
  border: 1px solid #e1e6eb;
  border-radius: 8px;
  padding: 12px 12px;

  &--deleted {
    .#{$baseClass} {
      &__pageName {
        color: #bfbfbf;
        font-style: italic;
      }
      &__pencilIcon {
        pointer-events: none;
        visibility: hidden;
      }
    }
  }
  &__pageName {
    @include text('body-2-strong');
  }
  &__slider {
    width: 100%;
    margin-right: 10px;
  }
  &__input {
    width: 60px;
  }
  &__recycleBinIcon {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
  &__pencilIcon {
    cursor: pointer;

    &:hover {
      color: #108ee9;
    }
  }
}
