@use '@/uikit/styles/colors.scss' as color;
@use '@/uikit/styles/text.scss' as *;
@use 'sass:map';

$baseClass: 'c-ffSidePanel';

$backdrop: rgb(55 65 81);

.#{$baseClass} {
  height: 100vh;
  background: color.$bg-neutral-2-default;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: -10px 0 20px 0 rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  display: flex;
  flex-direction: column;

  &--close {
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
  }

  &--open {
    &.#{$baseClass}--offsetLevel1 {
      transform: translateX(-50px);
    }

    &.#{$baseClass}--offsetLevel2 {
      transform: translateX(-100px);
    }

    &.#{$baseClass}--offsetLevel3 {
      transform: translateX(-150px);
    }

    &.#{$baseClass}--offsetLevel4 {
      transform: translateX(-200px);
    }
  }

  &__header {
    background-color: color.$bg-neutral-default;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
    padding: 0px 22px;
    border-bottom: 1px solid color.$st-neutral-default;
    min-height: 80px;
    position: relative;
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(55, 65, 81, 0.4);
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;

    &--open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__closeIcon {
    background-color: color.$bg-neutral-default;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    padding: 3px;
    position: absolute;
    left: -(calc(28px / 2));
    color: color.$fg-neutral-3-default;
    cursor: pointer;

    &:hover {
      color: color.$fg-error-default;
    }
  }

  &__title {
    margin: 0;
    @include text('title-1');
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__copyItems {
    display: flex;
    gap: 8px;
  }

  &__copyItem {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 8px;
    border-radius: 8px;
    padding: 2px 8px;
    cursor: pointer;
    border: 1px solid transparent;
    &:hover {
      background-color: color.$bg-neutral-2-default;
      border: 1px solid color.$st-neutral-2-default;
    }
  }

  &__copyItemTitle {
    grid-row: 1;
    grid-column: 2;
    @include text('body-2-strong');
    color: color.$fg-neutral-2-default;
  }

  &__copyItemValue {
    grid-row: 2;
    grid-column: 2;
    @include text('caption-1-strong');
    color: color.$fg-neutral-default;
  }

  &__copyItemIcon {
    grid-row: 1 / 3;
    grid-column: 1;
    align-self: center;
    justify-self: center;
    width: 24px;
    height: 24px;
  }

  &__body {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    padding: 16px 24px;
    height: calc(100% - 80px);
    overflow: hidden;
    flex: 1;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
    border: 1px solid color.$st-neutral-2-default;
    border-radius: 15px;
    width: max-content;
  }

  &__tab {
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 0px 18px 0px 25px;
    color: color.$fg-neutral-default;
    background-color: color.$bg-neutral-default;
    border-bottom: 1px solid color.$st-neutral-default;

    &--disabled {
      cursor: not-allowed;
      pointer-events: none;
      background-color: color.$bg-neutral-disabled;
      opacity: 0.5;
    }

    &--active {
      background-color: color.$bg-info-default;
      color: color.$fg-info-active; 

      span {
        color: color.$fg-info-active;
      }

      .#{$baseClass} {
        &__tabIcon {
          path {
            stroke: color.$fg-info-active;
            fill: transparent;
          }
        }
      }
    }

    &--inactive {
      &:hover {
        background-color: color.$bg-info-2-default;
        color: color.$fg-info-2-default;
      }
    }

    &:first-child {
      border-radius: 15px 15px 0 0;
    }

    &:last-child {
      border-radius: 0 0 15px 15px;
      border: none;
    }
  }

  &__tabTitle {
    width: max-content;
    @include text('body-2');
  }

  &__children {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
  }
}