@use "@/uikit/styles/colors.scss" as color;
@use "@/uikit/styles/base_typography.scss" as font;
@use "sass:map";

$baseClass: 'c-conditionForm';

.#{$baseClass} {
  &__sectionBox {
    position: relative;
  }
  &__container {
    .ant-ribbon-wrapper {
      position: absolute;
      top: 0;
      right: 20px;
    }
  }
  &__routes {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #e1e6eb;
  }
  &__routesHeader {
    padding: 20px 30px;
    background-color: #f9fafc;
    gap: 8px;
    align-items: center;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #e1e6eb;
  }
  &__defaultRoute {
    min-height: 60px;
    padding: 16px;
    border-bottom: 1px solid #e1e6eb;
    background-color: #fff;
    color: #8c939f;
    font-weight: 600;
  }
  &__routeIndex {
    min-width: 22px;
    min-height: 22px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #0958d9;
    background-color: #e6f4ff;
    border: 1px solid #b9ddff;
    border-radius: 50%;
  }
}
